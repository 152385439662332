import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Task, TaskId } from "~/domain/SearchTask/SearchTask.types";
import { BASE_URL } from "../api/const";
import { SearchState } from "./types";
import { Cluster } from "~/domain/Cluster/Cluster.types";
import { selectState } from "./selectors";
import { AppDispatch, RootState } from "../store";
import { TERMINAL_STATES } from "./const";

// -- API ----------------------------------------------------------------------

function getSearchTask(taskId: TaskId) {
  return axios.get<Task>(`${BASE_URL}/search_tasks/${taskId}`);
}

function getSearchClusters(taskId: TaskId, params?: { order_by: string }) {
  return axios.get<Array<Cluster>>(
    `${BASE_URL}/search_tasks/${taskId}/clusters`,
    { params },
  );
}

// -----------------------------------------------------------------------------
// -- Const --------------------------------------------------------------------

const SEARCH_STATE_MAP: Record<string, SearchState> = {
  [JSON.stringify({
    status: "Queued",
    processingStep: "NO_DATA",
  })]: "created",
  [JSON.stringify({
    status: "Running",
    processingStep: "NO_DATA",
  })]: "launched",
  [JSON.stringify({
    status: "Running",
    processingStep: "MILVUS_SEARCH",
  })]: "started",
  [JSON.stringify({
    status: "Running",
    processingStep: "CLUSTERING",
  })]: "continued",
  [JSON.stringify({
    status: "Finished",
    processingStep: "FINISHED",
  })]: "finished",
  [JSON.stringify({
    status: "PartiallyFinished",
    processingStep: "FINISHED",
  })]: "partiallyFinished",
  [JSON.stringify({
    status: "Error",
    processingStep: "ERROR",
  })]: "failed",
};

// -----------------------------------------------------------------------------

let stateTimer: ReturnType<typeof setTimeout> | null = null;

export const trackState = createAsyncThunk(
  "search/trackState",
  async (taskId: TaskId, { dispatch, rejectWithValue }) => {
    try {
      const { data: task } = await getSearchTask(taskId);
      const searchState =
        task.status && task.processing_step
          ? SEARCH_STATE_MAP[
              JSON.stringify({
                status: task.status,
                processingStep: task.processing_step,
              })
            ]
          : null;

      if (
        (!searchState || !TERMINAL_STATES.includes(searchState)) &&
        window.location.pathname.startsWith("/search/task")
      ) {
        stateTimer = setTimeout(() => dispatch(trackState(taskId)), 2000);
      } else {
        stateTimer && clearTimeout(stateTimer);
      }

      return {
        state: searchState,
        task,
      };
    } catch (err) {
      if (err instanceof Error) {
        rejectWithValue(err.message);
      }

      throw err;
    }
  },
);

let resultTimer: ReturnType<typeof setTimeout> | null = null;

export const trackResult = createAsyncThunk<
  Array<Cluster>,
  TaskId,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>(
  "search/trackResult",
  async (taskId, { dispatch, rejectWithValue, getState }) => {
    try {
      const { data: clusters } = await getSearchClusters(taskId);
      const state = selectState(getState());

      if (
        (!state || !TERMINAL_STATES.includes(state)) &&
        window.location.pathname.startsWith("/search/task")
      ) {
        resultTimer = setTimeout(() => dispatch(trackResult(taskId)), 2000);
      } else {
        resultTimer && clearTimeout(resultTimer);
      }

      return clusters;
    } catch (err) {
      if (err instanceof Error) {
        rejectWithValue(err.message);
      }

      throw err;
    }
  },
);
