import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../hooks";
import { TaskId } from "~/domain/SearchTask/SearchTask.types";
import { trackState, trackResult } from "./effects";
import { actions } from "./state";

export function useSearchStateTrackingEffect(taskId: TaskId): void {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(trackState(taskId));
  }, [dispatch, taskId]);
}

export function useSearchResultTrackingEffect(taskId: TaskId): void {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(trackResult(taskId));
  }, [dispatch, taskId]);
}

export function useResetOnDestroyEffect(): void {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);
}

export function useClusterFocusHandler() {
  const dispatch = useAppDispatch();

  return useCallback(
    (id: string) => {
      dispatch(actions.focus(id));
    },
    [dispatch],
  );
}
