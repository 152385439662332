import * as React from "react";
import { Cancel } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DirectionsIcon from "@mui/icons-material/Directions";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import ImportContactsRoundedIcon from "@mui/icons-material/ImportContactsRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStore from "../services/store/store";
import { useState, useEffect } from "react";
import { createTask, fetchTasks } from "../services/api";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";

const Wrapper = styled.div`
  padding: 0;

  .input {
    display: flex;
    align-items: center;
    width: 546px;
    box-shadow: none;
    border: 2px solid #005bff;
    overflow: hidden;
    border-radius: 8px 12px 12px 8px;
    input::placeholder {
      font-weight: 700;
      font-size: 20px;
    }

    ${(props) =>
      props.small &&
      `
      width: 356px;
    `}
  }

  .actions {
    margin-top: 8px;
    display: flex;
    color: rgba(0, 0, 0, 0.72);
  }

  .search-history {
    max-height: 300px;
    overflow: auto;

    h4 {
      cursor: pointer;
    }
  }
`;

const SearchBar = ({ small = false, onSelectFromHistory, showHistory }) => {
  const setOpenOverlay = useStore((state) => state.setOpenOverlay);
  // const setOpenOverlay = useStore((state) => state.setOpenOverlay);
  const [selectedFoses, selectedPeriod, resetFilters, search, setSearch] =
    useStore((state) => [
      state.selectedFoses,
      state.period,
      state.resetFilters,
      state.search,
      state.setSearch,
    ]);
  const [query, setQuery] = useState("");
  const [historyList, setHistoryList] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const initOverlay = async () => {
      try {
        const res = await fetchTasks({ params: { page: 1, size: 15 } });
        setHistoryList(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    initOverlay();
  }, []);

  const postSearch = async (search) => {
    setLoading(true);
    resetFilters(resetFilters);
    const model = {
      search_query: search,
      fos_ids: selectedFoses,
      year_from: selectedPeriod.from,
      year_to: selectedPeriod.to,
    };

    const res = await createTask(model);
    history.push(`/search/task/${res.data.id}`);
  };

  return (
    <Wrapper component="form" small={small}>
      <div className="input">
        <InputBase
          size="medium"
          fullWidth
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "search google maps" }}
        />

        <IconButton
          size="small"
          variant="contained"
          type="primary"
          onClick={() => setQuery("")}
          color="secondary"
          aria-label="directions"
        >
          <HighlightOffRoundedIcon fontSize="10px" color="secondary" />
        </IconButton>
        <IconButton
          size="small"
          variant="contained"
          type="primary"
          onClick={() => setOpenOverlay({ HISTORY: "HISTORY" })}
          aria-label="directions"
        >
          <ImportContactsRoundedIcon fontSize="10px" color="secondary" />
        </IconButton>
        <Button
          onClick={() => postSearch(search)}
          type="primary"
          color="primary"
          variant="contained"
          sx={{
            p: "10px",
            border: "none",
            boxShadow: "none",
            width: small ? "36px" : "112px",
            borderRadius: 0,
          }}
          aria-label="search"
        >
          <SearchIcon />
        </Button>
      </div>
      <div className="actions">
        <Button
          type="primary"
          color="secondary"
          endIcon={<ExpandMoreIcon />}
          onClick={() => setOpenOverlay({ FIELDS: "FIELDS" })}
          variant="contained"
          sx={{
            p: "10px",
            border: "none",
            boxShadow: "none",
            width: "112px",
            borderRadius: "8px",
            flex: 1,
            mr: "8px",
            justifyContent: "space-between",
            color: "rgba(0, 0, 0, 0.72)",
          }}
          aria-label="search"
        >
          Field
        </Button>
        <Button
          type="primary"
          color="secondary"
          onClick={() => setOpenOverlay({ PERIOD: "PERIOD" })}
          endIcon={<ExpandMoreIcon />}
          variant="contained"
          sx={{
            p: "10px",
            border: "none",
            boxShadow: "none",
            width: "112px",
            borderRadius: "8px",
            color: "rgba(0, 0, 0, 0.72)",
          }}
          aria-label="period"
        >
          Period
        </Button>
      </div>
      {showHistory && (
        <div className="search-history" style={{ width: "546px" }}>
          {historyList.map((i) => (
            <div
              key={i.id}
              style={{
                opacity: i.status === "Finished" ? 1 : 0.1,
                pointerEvents: i.status === "Error" ? "none" : "auto",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Typography
                  key={i.id}
                  onClick={() => onSelectFromHistory(i)}
                  sx={{ mt: 1 }}
                  variant="h4"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {i.search_query}
                  {i.status === "Error" && (
                    <Cancel style={{ marginLeft: "8px" }} />
                  )}
                </Typography>
                <Typography variant="subtitle1">{i.created_at}</Typography>
              </Box>
              {i.year_from && i.year_to && (
                <Typography
                  key={i.id}
                  onClick={() => onSelectFromHistory(i)}
                  sx={{ mt: 1 }}
                  variant="subtitle1"
                >
                  {`${i.year_from} - ${i.year_to}`}
                </Typography>
              )}
            </div>
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default SearchBar;
