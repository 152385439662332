import axios from "axios";
import {
  InstitutionId,
  InstitutionInfo,
} from "../../domain/Institution/Institution.types";
import { BASE_HOST, BASE_URL } from "./const";
import { WorksByYear } from "~/domain/Work/Work.types";
import { FieldsOfStudyLevel } from "../ResearchersInterestsTimeline/effects";

export const getInstitutionById = async (id: InstitutionId) => {
  return axios.get<InstitutionInfo>(`${BASE_URL}/institution/${id}`);
};

export const getInstitutionFieldsOfStudiesByYears = async (options: {
  id: InstitutionId;
  level: FieldsOfStudyLevel;
}) => {
  return axios.get<WorksByYear>(
    `${BASE_HOST}/api/v2/institution/${options.id}/fos_by_year`,
    { params: { level: options.level } },
  );
};
