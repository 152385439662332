import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const CrownIcon: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="3x" viewBoxCoef="3x" {...props}>
      <path
        className={styles.shape}
        d="M21.0005 12C21.0005 17.5842 17.5847 21 12.0005 21C6.41629 21 3.00049 17.5842 3.00049 12C3.00049 6.4158 6.41629 3 12.0005 3C17.5847 3 21.0005 6.4158 21.0005 12ZM7.55756 9.6C6.99136 9.6 6.99665 11.2001 7.00136 12.6228C7.00178 12.7504 7.0022 12.8765 7.0022 13C7.0022 15 7.83604 16 12.0013 16C16.1665 16 16.9988 15 16.9988 13C16.9988 12.8766 16.9992 12.7505 16.9996 12.6229C17.0043 11.2002 17.0096 9.6 16.4434 9.6C16.0676 9.6 15.7366 10.1921 15.4333 10.7346C15.2884 10.9939 15.1498 11.2418 15.0157 11.4083C14.7773 11.7043 14.6708 11.75 14.4823 11.3167C14.4627 11.2717 14.4414 11.2223 14.4185 11.169C14.0054 10.2105 13.0527 8 12.0005 8C10.9483 8 9.99558 10.2105 9.58252 11.169C9.55955 11.2223 9.53825 11.2717 9.51869 11.3167C9.33019 11.75 9.22366 11.7043 8.9853 11.4083C8.8512 11.2418 8.71261 10.9939 8.56769 10.7347C8.2644 10.1922 7.93336 9.6 7.55756 9.6Z"
      />
    </Icon>
  );
};

export default CrownIcon;
