import { FC, HTMLProps, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./FavouriteItem.module.scss";
import Layers from "~/icons/Layers";
import User from "~/icons/User";
import Building from "~/icons/Building";
import { IconProps } from "~/icons/Icon";
import TrashBin from "~/icons/TrashBin";
import { EventObject } from "~/ui-kit/EventObject";
import { FavouriteId } from "../types";
import Subscribtion from "~/components/Subscribtion";
import { type SubscribtionParams } from "~/components/Subscribtion/Subscribtion";
import axios from "axios";
import { BASE_URL } from "~/store/api/const";

interface FavouriteItemProps
  extends Pick<HTMLProps<HTMLLIElement>, "className"> {
  id: FavouriteId;
  type: "cluster" | "author" | "institution";
  name: string;
  filterString: string;
  subscribed: boolean;
  onClick: (event: EventObject<FavouriteId>) => void;
  onDelete: (event: EventObject<FavouriteId>) => void;
}

const FavouriteIconByType: Record<
  "cluster" | "author" | "institution",
  FC<IconProps>
> = {
  cluster: Layers,
  author: User,
  institution: Building,
};

const FavouriteItem: FC<FavouriteItemProps> = ({
  id,
  type,
  name,
  filterString,
  className,
  subscribed,
  onDelete,
  onClick,
}) => {
  const FavouriteIcon = useMemo(() => FavouriteIconByType[type], [type]);
  const [optimisticValue, setOptimisticValue] = useState<boolean>(subscribed);
  
  return (
    <li className={cn(className, styles.item)}>
      <header className={styles.header}>
        <FavouriteIcon className={styles.icon} />
        <h3
          onClick={() => onClick(new EventObject(id))}
          className={styles.name}
        >
          {name}
        </h3>
        <Subscribtion
          className={styles["subscribtion-button"]}
          value={optimisticValue}
          onSubscribe={(params: SubscribtionParams): void => {
            axios
              .post(`${BASE_URL}/favorite/${id}/subscribe`, params)
              .catch(() => {
                setOptimisticValue(subscribed);
              });

            setOptimisticValue(true);
          }}
          onUnsubscribe={function (): void {
            axios
              .post(`${BASE_URL}/favorite/${id}/unsubscribe`)
              .catch(() => {
                setOptimisticValue(subscribed);
              });

            setOptimisticValue(false);
          }}
        />
        <TrashBin
          className={styles.deleteButton}
          onClick={() => onDelete(new EventObject(id))}
        />
      </header>
      <footer className={styles.footer}>{filterString}</footer>
    </li>
  );
};

export default FavouriteItem;
