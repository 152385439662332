import { AuthorId } from "~/domain/Author/Author.types";
import { FieldOfStudyId } from "~/domain/FieldOfStudy/FieldOfStudy.types";
import { InstitutionId } from "~/domain/Institution/Institution.types";
import { SourceId } from "~/domain/Sources/Sources.types";

export type FavouriteType = "institution" | "author" | "cluster";

export type FavouriteId = string;

export interface FavouriteDTO {
  id: string;
  name: string;
  scope_type: string;
  scope_id: string;
  object_type: FavouriteType;
  object_id: string;
  subscribed: boolean;
  filters: {
    year_from: number;
    year_to: number;
    foses: Array<FieldOfStudyId>;
    sources: Array<SourceId>;
    authors: Array<AuthorId>;
    institutions: Array<InstitutionId>;
  };
  filter_labels: {
    sources: Array<string>;
    foses: Array<string>;
    authors: Array<string>;
    institutions: Array<string>;
  };
}

export interface Favourite {
  id: string;
  name: string;
  type: FavouriteType;
  contextId: string;
  scope: string;
  scopeId: string;
  searchParamsQueryString: string;
  filtersLabel: string;
  subscribed: boolean;
}

export function map(dto: FavouriteDTO): Favourite {
  const result: Favourite = {
    id: dto.id,
    name: dto.name,
    type: dto.object_type,
    contextId: dto.object_id,
    scope: dto.scope_type,
    scopeId: dto.scope_id,
    searchParamsQueryString: buildSearchParamsQueryString(dto.filters),
    filtersLabel: buildFiltersLabel(dto.filters, dto.filter_labels),
    subscribed: dto.subscribed,
  };

  return result;
}

function buildSearchParamsQueryString(
  filtersObject: FavouriteDTO["filters"],
): string {
  const queryObject: {
    year_from: string;
    year_to: string;
    sources?: string;
    authors?: string;
    institutions?: string;
    foses?: string;
  } = {
    year_from: `${filtersObject.year_from}`,
    year_to: `${filtersObject.year_to}`,
  };

  if (filtersObject.sources.length)
    queryObject.sources = filtersObject.sources.join(",");

  if (filtersObject.authors.length)
    queryObject.authors = filtersObject.authors.join(",");

  if (filtersObject.institutions.length)
    queryObject.institutions = filtersObject.institutions.join(",");

  if (filtersObject.foses.length)
    queryObject.foses = filtersObject.foses.join(",");

  return new URLSearchParams(queryObject).toString();
}

function buildFiltersLabel(
  { year_from, year_to }: FavouriteDTO["filters"],
  { sources, foses, authors, institutions }: FavouriteDTO["filter_labels"],
): string {
  return [
    sources.join(", "),
    foses.join(", "),
    authors.join(", "),
    institutions.join(", "),
    `${year_from} - ${year_to}`,
  ].join(" • ");
}
