import { type FC } from "react";

import CiteIcon from "~/icons/Cite";

import type { DateString } from "~/domain/basic.types";
import type { WorkId } from "~/domain/Work/Work.types";

import styles from "./WorkItem.module.scss";

interface WorkItemProps {
  id: WorkId;
  title: string;
  publicationDate: DateString;
  fieldsOfStudy?: Array<string>;
  citeCount: number;
  authors?: Array<string>;
  onClick: (id: WorkId) => void;
}

const WorkItem: FC<WorkItemProps> = (props) => {
  return (
    <article
      className={styles.workItem}
      onClick={() => props.onClick(props.id)}
    >
      <header className={styles.header}>
        <time dateTime={props.publicationDate} className={styles.date}>
          {props.publicationDate}
        </time>
        {props.fieldsOfStudy &&
          props.fieldsOfStudy.slice(0, 2).map((fieldOfStudy, index) => (
            <span key={index} className={styles.fieldOfStudy}>
              {fieldOfStudy}
            </span>
          ))}
      </header>
      <h4
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: props.title }}
      ></h4>
      <footer className={styles.footer}>
        <p className={styles.citeCount}>
          <CiteIcon sizeCoef="2x" />
          {props.citeCount}
        </p>
        {props.authors && <AuthorsList authors={props.authors} />}
      </footer>
    </article>
  );
};

export default WorkItem;

const AuthorsList: FC<{ authors: Array<string> }> = ({ authors }) => {
  let list;

  if (authors.length === 1) {
    list = <span className={styles.author}>{authors.at(0)}</span>;
  } else if (authors.length === 2) {
    list = [
      <span key={0} className={styles.author}>
        {authors.at(0)},
      </span>,
      <span key={1} className={styles.author}>
        {authors.at(1)}
      </span>,
    ];
  } else {
    list = [
      <span key={0} className={styles.author}>
        {authors.at(0)}
      </span>,
      authors.length - 2 > 0 && (
        <span key={1} className={styles.authorsCount}>
          +{authors.length - 2}
        </span>
      ),
      <span key={2} className={styles.author}>
        {authors.at(-1)}
      </span>,
    ];
  }

  return <p className={styles.authors}>{list}</p>;
};
