import { type FC } from "react";
import Favourites from "~/components/Favourites";
import styles from "./ActivityFeed.module.scss";
import Paper from "~/ui-kit/Paper";
import Recommendations from "~/components/Recommendations";

const TEMP_STYLES = {
  padding: "24px",
  height: "100%",
  filter: "blur(2px)",
};

const ActivityFeed: FC = () => {
  return (
    <main className={styles.view}>
      <section>
        <Recommendations />
      </section>
      <section>
        <Paper style={TEMP_STYLES}>
          {/* @TODO News */}
          <h3>News</h3>
        </Paper>
      </section>
      <section style={{ height: "50vh" }}>
        <Favourites />
      </section>
    </main>
  );
};

export default ActivityFeed;
