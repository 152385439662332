import type { Cluster, ClusterId } from "~/domain/Cluster/Cluster.types";
import { Folder } from "./types";
import { flow, makeAutoObservable } from "mobx";
import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "~/store/api/const";

export type FolderId = string;

interface FolderStatus {
  percent: number;
  status: "IN_PROGRESS" | "FAILED" | "FINISHED";
}

interface SecondLevelClusteringStatus {
  percent: number;
  status: "IN_PROGRESS" | "FAILED" | "FINISHED" | "NOT_STARTED";
}

export interface Topic extends Cluster {
  trend: {
    week: number;
    month: number;
    quarter: number;
  };
  isOpen?: boolean;
}

interface FolderCluster extends Topic {
  subclusters?: Array<Topic>;
}

interface FolderOpenOptions {
 onClustersLoaded?: (clusters: Array<FolderCluster>) => void;
}

class FolderState {
  private _fetchingProcess: boolean = false;
  private _fetchingClustersProcess: boolean = false;
  secondLevelClustersProcesses: Record<ClusterId, boolean> = {};
  private _secondLevelClustersFetchingErrors: Record<ClusterId, string> = {};
  private _folder: Folder | null = null;
  private trackingStatusTimer: ReturnType<typeof setTimeout> | null = null;
  private _status: FolderStatus | null = null;
  private _clusters: Array<FolderCluster> | null = null;
  private _firstLevelClustersState: Record<ClusterId, boolean> = {};
  private _secondLevelClusters: Record<ClusterId, Array<Topic>> = {};
  secondLevelClusteringStatuses: Record<
    ClusterId,
    SecondLevelClusteringStatus
  > = {};
  private secondLevelClusteringStatusTimers: Record<
    ClusterId,
    ReturnType<typeof setTimeout>
  > = {};
  secondLevelClusteringError: Record<ClusterId, string> = {};
  searchPattern: string = "";
  trendBy: "week" | "month" | "quarter" = "month";
  fetchingError: string | null = null;
  clusteringError: string | null = null;
  fetchingClustersError: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  getSecondLevelClusters(id: ClusterId) {
    return this._secondLevelClusters[id]
      ?.slice()
      .sort((ca, cb) => cb.trend[this.trendBy] - ca.trend[this.trendBy]);
  }

  open = flow(function* (this: FolderState, id: string, options: FolderOpenOptions = {}) {
    if (!this._folder) yield this.fetch(id);

    this.trackStatus(id, options);
  });

  destroy() {
    if (this.trackingStatusTimer) clearTimeout(this.trackingStatusTimer);

    Object.values(this.secondLevelClusteringStatusTimers).forEach((timer) =>
      clearTimeout(timer),
    );

    this.folder = null;
    this.status = null;
    this._clusters = null;
  }

  toggleCluster(id: ClusterId) {
    if (!this._firstLevelClustersState[id]) {
      this.trackSecondLevelClusteringStatues(id);
    } else {
      clearTimeout(this.secondLevelClusteringStatusTimers[id]);
    }

    this._firstLevelClustersState[id] = !this._firstLevelClustersState[id];
  }

  isClusterOpen(id: ClusterId): boolean {
    return !!this._firstLevelClustersState[id];
  }

  fetch = flow(function* (this: FolderState, id: string) {
    this._fetchingProcess = true;

    try {
      const { data }: AxiosResponse<Array<Folder>> = yield axios.get<
        Array<Folder>
      >(`${BASE_URL}/folder`);
      const folder = data.find((f) => f.id === +id);

      if (folder) {
        this._folder = folder;
      } else {
        throw new Error("Folder Not Found");
      }
    } catch (err) {
      if (err instanceof Error) {
        this.fetchingError = err.message;
      }

      throw err;
    } finally {
      this._fetchingProcess = false;
    }
  });

  trackSecondLevelClusteringStatues = flow(function* (
    this: FolderState,
    id: ClusterId,
  ) {
    const { status }: SecondLevelClusteringStatus =
      yield this.fetchSecondLevelClusteringStatus(id);

    if (status === "NOT_STARTED") {
      yield this.startSecondLevelClustering(id);

      this.trackSecondLevelClusteringStatues(id);
    }

    if (status === "IN_PROGRESS") {
      this.secondLevelClusteringStatusTimers[id] = setTimeout(
        () => this.trackSecondLevelClusteringStatues(id),
        5000,
      );
    }

    if (status === "FAILED") {
      this.secondLevelClusteringError[id] = "Clustering error";
    }

    if (status === "FINISHED" && !this._secondLevelClusters[id]) {
      this._secondLevelClusters[id] = yield this.fetchSecondLevelClusters(id);
    }
  });

  trackStatus = flow(function* (this: FolderState, id: string, options: FolderOpenOptions = {}) {
    const { status }: FolderStatus = yield this.fetchStatus(id);

    if (status === "IN_PROGRESS") {
      this.trackingStatusTimer = setTimeout(() => this.trackStatus(id, options), 5000);
    }

    if (status === "FAILED") {
      this.clusteringError = "Clustering error";
    }

    if (status === "FINISHED") {
      this.clusters = yield this.fetchClusters(id);

      if (this.clusters) options.onClustersLoaded?.(this.clusters);
    }
  });

  get clusters() {
    return this._clusters
      ? this._clusters
          .filter((c) =>
            c.name.toLowerCase().includes(this.searchPattern.toLowerCase()),
          )
          ?.sort((ca, cb) => cb.trend[this.trendBy] - ca.trend[this.trendBy])
      : null;
  }

  set clusters(clusters: Array<FolderCluster> | null) {
    this._clusters = clusters;
  }

  fetchSecondLevelClusteringStatus = flow(function* (
    this: FolderState,
    id: ClusterId,
  ) {
    const { data } = yield axios.get<SecondLevelClusteringStatus>(
      `${BASE_URL}/folder/${id}/subclusters/status`,
    );

    this.secondLevelClusteringStatuses[id] = data;

    return data;
  });

  async startSecondLevelClustering(id: ClusterId) {
    await axios.post(`${BASE_URL}/folder/${id}/subclusters`);
  }

  async fetchStatus(id: FolderId) {
    const { data } = await axios.get<FolderStatus>(
      `${BASE_URL}/folder/${id}/status`,
    );

    this.status = data;

    return data;
  }

  fetchSecondLevelClusters = flow(function* (this: FolderState, id: string) {
    this.secondLevelClustersProcesses[id] = true;

    try {
      const { data }: AxiosResponse<Array<Cluster>> = yield axios.get<
        Array<Cluster>
      >(`${BASE_URL}/folder/${id}/subclusters`);

      return data;
    } catch (err) {
      if (err instanceof Error) {
        this._secondLevelClustersFetchingErrors[id] = err.message;
      }

      throw err;
    } finally {
      this.secondLevelClustersProcesses[id] = false;
    }
  });

  fetchClusters = flow(function* (this: FolderState, id: string) {
    this._fetchingClustersProcess = true;

    try {
      const { data }: AxiosResponse<Array<Cluster>> = yield axios.get<
        Array<Cluster>
      >(`${BASE_URL}/folder/${id}/clusters`);

      return data;
    } catch (err) {
      if (err instanceof Error) {
        this.fetchingClustersError = err.message;
      }

      throw err;
    } finally {
      this._fetchingClustersProcess = false;
    }
  });

  get folder() {
    return this._folder;
  }

  set folder(folder: Folder | null) {
    this._folder = folder;
  }

  get status() {
    return this._status;
  }

  set status(status: FolderStatus | null) {
    this._status = status;
  }

  get isLoading() {
    return this._fetchingProcess;
  }

  get isClustersLoading() {
    return this._fetchingClustersProcess;
  }
}

export default new FolderState();
