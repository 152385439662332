import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Box, StyledEngineProvider } from "@mui/material";

// @ts-expect-error
import Main from "./main/Main";
import TaskView from "~/views/Task";
// @ts-expect-error
import Overlays from "./Overlays/Overlays";
// @ts-expect-error
import { fetchUser } from "./services/api";
// @ts-expect-error
import useStore from "./services/store/store";

import "./App.css";
import styles from "./App.module.scss";
import Author from "./views/Author";
import InstitutionView from "./views/Institution";
import { ProvideAuth, useProvideAuth } from "./services/Auth";
import PrivateRoute from "./views/PrivateRoute/PrivateRoute";
import LoginView from "./views/Login/LoginView";
import ActivityFeed from "./views/ActivityFeed";
import Header from "./layouts/Header";
import MonitoringView from "./views/Monitoring/Monitoring";
import { ModalProvider } from "react-modal-hook";

function App() {
  const setUser = useStore((state: any) => state.setUser);
  const { signout } = useProvideAuth();

  useEffect(() => {
    const init = async () => {
      try {
        const res = await fetchUser();
        setUser(res.data);
      } catch (error) {
        signout();
      }
    };
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalProvider>
      <StyledEngineProvider injectFirst>
        <ProvideAuth>
          <div className={styles.app}>
            <Header />
            <Switch>
              <Route path="/search" exact component={Main} />
              <Route path="/search/task/:id" component={TaskView} />
              <Route path="/author/:id" component={Author} />
              <Route path="/login" component={LoginView} />
              <Route path="/monitoring" component={MonitoringView} />
              <PrivateRoute path="/institution/:id">
                <InstitutionView />
              </PrivateRoute>
              <Route exact path="/favorites" component={ActivityFeed} />
              <Redirect to="/search" />
            </Switch>
            <Overlays />
          </div>
        </ProvideAuth>
      </StyledEngineProvider>
    </ModalProvider>
  );
}

export default App;
