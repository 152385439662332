import {
  Button,
  ButtonGroup,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import List from "./List";
import useStore from "../services/store/store";
import {
  downloadAuthors,
  fetchAuthors,
  searchAuthorLinkedin,
} from "../services/api";
import Institutions from "./Institutions/Institutions";

const ListWrapper = styled(Stack)`
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }
`;

const StyledGroup = styled(ButtonGroup)`
  box-shadow: none;
  button {
    border-radius: 8px;
  }
`;

const AUTHORS = "AUTORS";

const INSTITUTIONS = "INSTITUTIONS";

const AuthorsList = ({ clusterId }) => {
  const [results, setResults] = useState([]);

  const [selectedView, setSelectedView] = useState(AUTHORS);
  const [user, setOpenOverlay] = useStore((state) => [
    state.user,
    state.setOpenOverlay,
  ]);
  const [period, fields, types, mainSort] = useStore((state) => [
    state.periodTypes,
    state.selectedFosesTypes,
    state.selectedWorkTypes,
    state.mainSort,
  ]);

  useEffect(() => {
    const fetchController = new AbortController();

    const initOverlay = async () => {
      const res = await fetchAuthors(clusterId, {
        params: {
          page: 1,
          size: 15,
          fos_ids: fields.join(","),
          venues: types.join(","),
          year_from: period.from,
          year_to: period.to,
          order_by: mainSort,
        },
        signal: fetchController.signal,
      });
      setResults(res.data);
    };

    if (clusterId) {
      initOverlay();
    }

    return () => {
      fetchController.abort();
    };
  }, [clusterId, fields.length, types.length, period, mainSort]);

  const onUpdate = (item) => {
    const list = [...results.items];
    list[list.findIndex((el) => el.id === item.id)] = item;
    setResults({ ...results, items: list });
  };

  const onDownLoad = async () => {
    if (!user) {
      setOpenOverlay({ LOGIN: "LOGIN" });
      return;
    }
    try {
      const res = await downloadAuthors(clusterId);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    results && (
      <ListWrapper
        sx={{
          background: "rgba(0, 0, 0, 0.02)",
          borderRadius: "8px",
          padding: "16px",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">Top authors</Typography>
          <IconButton onClick={onDownLoad}>
            <SaveAltIcon />
          </IconButton>
        </Box>
        <Stack sx={{ mt: 1 }}>
          <StyledGroup
            size="small"
            variant="contained"
            color="primary"
            aria-label="outlined primary button group"
          >
            <Button
              onClick={() => setSelectedView(AUTHORS)}
              color={selectedView === INSTITUTIONS ? "secondary" : "primary"}
            >
              Authors
            </Button>
            <Button
              onClick={() => setSelectedView(INSTITUTIONS)}
              color={selectedView === AUTHORS ? "secondary" : "primary"}
            >
              Organizations
            </Button>
          </StyledGroup>
        </Stack>
        <Stack mt="16px">
          {selectedView === AUTHORS && (
            <List list={results.items || []} onUpdate={onUpdate} totalItems={results.total} />
          )}
          {selectedView === INSTITUTIONS && (
            <Institutions clusterId={clusterId} />
          )}
          <Button
            variant="contained"
            onClick={() => setOpenOverlay({ AUTHORS: { clusterId } })}
            style={{ boxShadow: "none" }}
            color="lightBlue"
          >
            Show all
          </Button>
        </Stack>
      </ListWrapper>
    )
  );
};

export default AuthorsList;
