import { useCallback, type FC } from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useLocation } from "react-router-dom";

import Button from "~/ui-kit/Button";

import AddIcon from "~/icons/Add";

import styles from "./Filters.module.scss";
// @ts-expect-error
import useStore from "~/services/store/store";
import { useAppState } from "~/state";
import SortingMenu from "~/widgets/SortingMenu";

function useI18nText() {
  const { filters } = useAppState();

  return {
    emptyResult: "No data",
    filtrationInProgress: "Filtering...",
    filtrationResult: "works", // @i18n use `pluralize`
    sortByDate: "By relevance",
    fieldsOfStudiesLabel: filters.printfieldOfStudiesLabel("Fields of Studies"),
    sourcesLabel: filters.printSourcesLabel("Sources"),
    authorsLabel: filters.printAuthorsLabel("Authors"),
    periodLabel: filters.periodFilter.print("Period"),
  };
}

const CLUSTER_SORTING_OPTIONS = {
  date_asc: "By ascending publication date",
  date_desc: "By descending publication date",
  cited_asc: "By ascending citation",
  cited_desc: "By descending citation",
  relevance: "By relevance",
};

const AUTHOR_SORTING_OPTIONS = {
  date_asc: "By ascending publication date",
  date_desc: "By descending publication date",
  cited_asc: "By ascending citation",
  cited_desc: "By descending citation",
};

const Filters: FC = () => {
  const { filters, Works } = useAppState();
  const i18n = useI18nText();
  const handleFilterOpen = useOpenFilterHandlers();
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <section className={styles.filter}>
      <header className={styles.header}>
        <h1 className={styles.total}>
          {Works.isFirstPageLoading
            ? i18n.filtrationInProgress
            : Works.total
              ? `${Works.total.toLocaleString()} ${i18n.filtrationResult}`
              : i18n.emptyResult}
        </h1>
        <SortingMenu
          value={filters.sorting}
          options={
            filters.context === "search"
              ? CLUSTER_SORTING_OPTIONS
              : AUTHOR_SORTING_OPTIONS
          }
          onChange={(sortBy) => {
            filters.sortBy(sortBy);
            history.push({
              pathname,
              search: filters.queryString,
            });
          }}
        />
      </header>
      <nav className={styles.options}>
        <Button
          className={styles.filterButton}
          color={filters.fieldsOfStudiesCounter ? "primary" : "secondary"}
          onClick={handleFilterOpen["fieldsOfStudies"]}
        >
          {i18n.fieldsOfStudiesLabel}
          <AddIcon />
        </Button>
        <Button
          className={styles.filterButton}
          color={filters.sourcesCounter ? "primary" : "secondary"}
          onClick={handleFilterOpen["sources"]}
        >
          {i18n.sourcesLabel}
          <AddIcon />
        </Button>
        <Button
          className={styles.filterButton}
          color={filters.periodFilter.isEmpty() ? "secondary" : "primary"}
          onClick={handleFilterOpen["period"]}
        >
          {i18n.periodLabel}
          <AddIcon />
        </Button>
        <Button
          className={styles.filterButton}
          color={filters.authorsCounter ? "primary" : "secondary"}
          onClick={handleFilterOpen["authors"]}
        >
          {i18n.authorsLabel}
          <AddIcon />
        </Button>
        <Button
          color={filters.isFavourite ? "primary" : "secondary"}
          onClick={() => filters.toggleFavourite()}
        >
          Like it
        </Button>
      </nav>
    </section>
  );
};

export default observer(Filters);

function useOpenFilterHandlers() {
  // @ts-expect-error
  const setOpenOverlay = useStore((state) => state.setOpenOverlay);

  return {
    fieldsOfStudies: useCallback(
      () => setOpenOverlay({ FieldsOfStudiesFilter: true }),
      [setOpenOverlay],
    ),
    sources: useCallback(
      () => setOpenOverlay({ SourcesFilter: true }),
      [setOpenOverlay],
    ),
    authors: useCallback(
      () => setOpenOverlay({ AuthorsFilter: true }),
      [setOpenOverlay],
    ),
    period: useCallback(
      () => setOpenOverlay({ PeriodFilter: true }),
      [setOpenOverlay],
    ),
  };
}
