import axios from "axios";
import { makeAutoObservable } from "mobx";
import { BASE_URL } from "~/store/api/const";
import { Scope, ScopeValueId } from "../works";

interface RelativeWorksAmountByYear {
  year: number;
  amount: number;
  count: number;
}

interface Value {
  from?: number;
  to?: number;
}

class PeriodFilterState {
  constructor() {
    makeAutoObservable(this);
  }

  private _possibleValues: Array<RelativeWorksAmountByYear> = [];

  private _value: Value = {} as Value;

  private async fetchPossibleValues(scope: Scope, scopeValueId: ScopeValueId, signal?: AbortSignal) {
    const path = `${BASE_URL}/${mapScopeWithRequestSubfolder(scope)}/${scopeValueId}/period`;
    const { data } = await axios.get<Array<RelativeWorksAmountByYear>>(path, { signal });

    if (!data.length) {
      console.warn(path, "returns empty relative works timeline");
    }

    this._possibleValues = data;
  }

  private setupValueFromLocation() {
    const params = new URLSearchParams(window.location.search);

    if (params.has("year_from"))
      this._value.from = parseInt(params.get("year_from")!);

    if (params.has("year_to"))
      this._value.to = parseInt(params.get("year_to")!);
  }

  public async init(scope: Scope, scopeValueId: ScopeValueId, signal?: AbortSignal) {
    await this.fetchPossibleValues(scope, scopeValueId, signal);
    this.setupValueFromLocation();
  }

  public get possibleValues() {
    return this._possibleValues;
  }

  public get value() {
    return this._value;
  }

  public set value(value: Value) {
    if (value.from) this._value.from = value.from;

    if (value.to) this._value.to = value.to;
  }

  public get minPossibleValue() {
    if (!this._possibleValues.length) {
      console.error(new Error("Possible period filter values weren't get"));
      return 2014;
    }

    return this._possibleValues.at(0)!.year;
  }

  public get maxPossibleValue() {
    if (!this._possibleValues.length) {
      console.error(new Error("Possible period filter values weren't get"));
      return 2024;
    }

    return this._possibleValues.at(-1)!.year;
  }

  public isEmpty() {
    return !Object.values(this._value).length;
  }

  public print(label = "Period") {
    switch (true) {
      case !!(this._value.from && this._value.to):
        return `${this._value.from} — ${this._value.to}`;
      case !!this._value.from:
        return `${this._value.from} — ${this.maxPossibleValue}`;
      case !!this._value.to:
        return `${this.minPossibleValue} — ${this._value.to}`;
      default:
        return label;
    }
  }
}

export default new PeriodFilterState();

// @todo @YLeight make common
type RequestSubfolder = "authors" | "institution" | "clusters";

function mapScopeWithRequestSubfolder(scope: Scope): RequestSubfolder {
  if (scope === "author") return "authors";
  if (scope === "institution") return "institution";
  if (scope === "search") return "clusters";

  throw new Error("Unknown context for mapping");
}
