import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Tab,
  Tabs,
} from "@mui/material";
// @ts-expect-error
import MainText from "../../../mainText/MainText";
import { useEffect, useRef, useState, type FC } from "react";
import MarkdownViewer from "~/main/MarkdownViewer";
import Filters from "~/components/Filters";
import Works from "~/components/Works";
// @ts-expect-error
import AuthorsList from "../../../AuthorsList/AuthorsList";
import { useLocation, useParams } from "react-router-dom";
import { Cluster, ClusterId } from "~/domain/Cluster/Cluster.types";
import axios from "axios";
// @ts-expect-error
import Trends from "../../../trends/Trends";
import { BASE_URL } from "~/store/api/const";
import { useAppState } from "~/state";
import { FolderId } from "~/widgets/Folder/Folder.state";

const ClusterView: FC = () => {
  const { clusterId, folderId } = useParams<{
    folderId: FolderId;
    clusterId: ClusterId;
  }>();
  const [focusedCluster, setFocusedCluster] = useState<Cluster | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const reqCtrl = useRef<AbortController>();

  useEffect(() => {
    reqCtrl.current = new AbortController();

    fetch();

    async function fetch() {
      let { data: cluster } = await axios.get<Cluster>(
        `${BASE_URL}/clusters/${clusterId}`,
        { signal: reqCtrl.current?.signal },
      );

      if (
        cluster.maturity_reasoning === null ||
        cluster.potential_reasoning === null
      ) {
        cluster = (
          await axios.post(`${BASE_URL}/folder/${clusterId}/collect_data`, {
            signal: reqCtrl.current?.signal,
          })
        )?.data;
      }

      setFocusedCluster(cluster);
    }

    return () => {
      reqCtrl.current?.abort();
    };
  }, [clusterId]);

  const { filters } = useAppState();
  const { search } = useLocation();

  useEffect(() => {
    filters.init(
      "folder",
      folderId,
      "search",
      clusterId,
      new URLSearchParams(search),
      reqCtrl.current?.signal,
    );

    return () => {
      reqCtrl.current?.abort();
    };
  }, [clusterId, filters, folderId, search]);

  if (!focusedCluster) {
    return <Skeleton />;
  }

  return (
    <>
      <Box sx={{ p: 2 }} display="flex" flexDirection="column">
        <MainText selectedCluster={focusedCluster} />
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={7} sx={{ p: 2 }}>
            <MarkdownViewer subtopics={focusedCluster.subtopics}>
              {focusedCluster.summary}
            </MarkdownViewer>
          </Grid>
          <Grid item xs={5}>
            <Box sx={{ p: 2 }} display="flex" flexDirection="column">
              <Tabs
                value={tabValue}
                onChange={(e, newValue) => setTabValue(newValue)}
                style={{ marginBottom: "8px" }}
              >
                <Tab label="Maturity" />
                <Tab label="Potential" />
                <Tab label="Trends" />
              </Tabs>
              <div>
                {tabValue === 0 && (
                  <div
                    style={{
                      height: "220px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {focusedCluster.maturity_reasoning === null ? (
                      <CircularProgress />
                    ) : (
                      <MarkdownViewer>
                        {focusedCluster.maturity_reasoning}
                      </MarkdownViewer>
                    )}
                  </div>
                )}
              </div>
              <div>
                {tabValue === 1 && (
                  <p
                    style={{
                      height: "220px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {focusedCluster.potential_reasoning === null ? (
                      <CircularProgress />
                    ) : (
                      <MarkdownViewer>
                        {focusedCluster.potential_reasoning}
                      </MarkdownViewer>
                    )}
                  </p>
                )}
              </div>
              <div>
                {tabValue === 2 && <Trends selectedCluster={focusedCluster} />}
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} padding="16px">
            <Filters />
          </Grid>
          <Grid item xs={7}>
            <Box sx={{ p: 2 }} display="flex" flexDirection="column">
              <Works scope="search" scopeValueId={focusedCluster.id} />
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box sx={{ p: 2 }} display="flex" flexDirection="column">
              <AuthorsList clusterId={clusterId} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ClusterView;
