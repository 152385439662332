import { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import FoldersLayout from "./FoldersLayout";
import FolderLayout from "./FolderLayout/FolderLayout";

const MonitoringView: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={FoldersLayout} />
      <Route path={`${path}/folder/:folderId`} component={FolderLayout} />
    </Switch>
  );
};

export default MonitoringView;
