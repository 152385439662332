import axios from "axios";
import { FC, useEffect, useState } from "react";
import Crown from "~/icons/Crown";
import { BASE_URL } from "~/store/api/const";
import styles from "./styles.module.scss";
import Paper from "~/ui-kit/Paper";
import { useHistory } from "react-router-dom";
// @ts-expect-error
import { createTask } from "~/services/api";

function useSearchTaskAction() {
  const history = useHistory();

  /**
   * Creates `Search Task` and redirects to it's page
   */
  return async function createSearchTask(search_query: string, year_from = 2014, year_to = new Date().getFullYear()) {
    const { data: task } = await createTask({
      search_query,
      year_from,
      year_to
    });

    history.push(`/search/task/${task.id}`);
  };
}

const Recommendations: FC = () => {
  const [recommendations, setRecommendations] = useState([]);
  const createSearchTask = useSearchTaskAction();

  useEffect(() => {
    fetchRecommendations();

    async function fetchRecommendations() {
      const { data } = await axios.get(`${BASE_URL}/recommendations`);

      setRecommendations(data.fos);
    };
  }, []);

  return (
    <Paper className={styles.recommendations}>
      <h1>Recommendations</h1>
      <ul>
        {recommendations.map(({ id, display_name, last_recommended_at }) => (
          <li key={id} className={styles.recommendation}>
            <i className={styles.icon}>
              <Crown />
            </i>
            <p>
              <i>
                <span>Field of study</span>
                <span>
                  {new Date(last_recommended_at).toLocaleDateString()}
                </span>
              </i>
              <button onClick={() => createSearchTask(display_name)}>
                {display_name}
              </button>
            </p>
          </li>
        ))}
      </ul>
    </Paper>
  );
};

export default Recommendations;
