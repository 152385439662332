import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const Subscribed: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="2x" viewBoxCoef="2x" {...props}>
      <g className={styles.shape}>
        <path d="M6.92948 0.5C7.38487 0.5 7.75404 0.873096 7.75404 1.33333C8.05227 1.36564 8.32636 1.4162 8.5786 1.48277C9.40316 1.7004 9.40316 2.1177 9.15292 2.54406C8.78807 3.16571 8.5786 3.89147 8.5786 4.66667C8.5786 6.96785 10.4244 8.83333 12.7014 8.83333C13.1137 8.83333 13.526 8.83333 13.526 9.66669C13.526 11.3334 11.0596 13 6.92948 13C2.79932 13 0.332995 11.3334 0.333008 9.66669C0.333008 8.78766 0.695239 8.32073 1.075 7.83121C1.23527 7.62462 1.39866 7.414 1.53927 7.16668C1.77027 6.76035 1.91395 6.20542 2.07109 5.59851L2.07608 5.57925C2.52456 3.84696 3.09091 1.65937 6.10492 1.33333C6.10492 0.873096 6.47409 0.5 6.92948 0.5Z" />
        <path d="M4.5685 14.4175C4.39934 14.174 4.41163 13.8155 4.70585 13.8333C4.70585 13.8333 5.69517 13.9896 6.92945 13.9896C8.17735 13.9896 9.15292 13.8336 9.15292 13.8336C9.44633 13.8125 9.46224 14.1467 9.29683 14.3921C8.84999 15.0552 7.87242 15.5 6.92945 15.5C5.9987 15.5 5.01917 15.0664 4.5685 14.4175Z" />
        <path d="M15.7581 2.41074C15.4361 2.08531 14.914 2.08531 14.592 2.41074L12.7014 4.32149L11.6353 3.24408C11.3133 2.91864 10.7912 2.91864 10.4692 3.24408C10.1472 3.56951 10.1472 4.09715 10.4692 4.42259L12.1183 6.08926C12.4404 6.41469 12.9624 6.41469 13.2844 6.08926L15.7581 3.58926C16.0801 3.26382 16.0801 2.73618 15.7581 2.41074Z" />
      </g>
    </Icon>
  );
};

export default Subscribed;
