import { HTMLProps, useState, type FC } from "react";
import classNames from "classnames";
import { TextField } from "@mui/material";
import Subscribed from "~/icons/Subscribed";
import Subscribe from "~/icons/Subscribe";
import Button from "~/ui-kit/Button";
import useModal from "~/ui-kit/useModal";
import styles from "./Subscribtion.module.scss";

type Periodicity = "every_week" | "every_2_weeks" | "every_month";

export interface SubscribtionParams {
  email: string;
  periodicity: Periodicity;
}

export interface SubscriptionProps {
  onSubscribe: (params: SubscribtionParams) => void;
  onUnsubscribe: () => void;
  value?: boolean;
  className?: string;
};

export interface SubscribtionDialogProps {
  onSubscribe: (params: SubscribtionParams) => void;
};

const SubscribtionDialog: FC<SubscribtionDialogProps> = ({
  onSubscribe,
}) => {
  const [email, setEmail] = useState('');
  const [periodicity, setPeriodicity] = useState<SubscribtionParams["periodicity"]>('every_week');

  return (
    <form className={styles["subscribtion-form"]}>
      <header>
        <h1>We will send new works</h1>
      </header>
      <section>
        <TextField
          label="Email"
          variant="filled"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <fieldset>
          <div>
            <input
              id="every_week"
              type="radio"
              name="periodicity"
              value="every_week"
              checked={periodicity === "every_week"}
              onChange={(e) => setPeriodicity(e.target.value as Periodicity)}
            />
            <label htmlFor="every_week">Every week</label>
          </div>
          <div>
            <input
              id="every_2_weeks"
              type="radio"
              name="periodicity"
              value="every_2_weeks"
              checked={periodicity === "every_2_weeks"}
              onChange={(e) => setPeriodicity(e.target.value as Periodicity)}
            />
            <label htmlFor="every_2_weeks">Every two weeks</label>
          </div>
          <div>
            <input
              id="every_month"
              type="radio"
              name="periodicity"
              value="every_month"
              checked={periodicity === "every_month"}
              onChange={(e) => setPeriodicity(e.target.value as Periodicity)}
            />
            <label htmlFor="every_month">Every month</label>
          </div>
        </fieldset>
        <Button
          className={styles["subscribe-button"]}
          color="primary"
          disabled={!email.length || !periodicity.length}
          onClick={() => onSubscribe({ email, periodicity })}
        >
          Subscribe
        </Button>
      </section>
    </form>
  );
};

const Subscribtion: FC<SubscriptionProps> = ({ value = false, className, onSubscribe, onUnsubscribe }) => {
  const openSubscribtionDialog = useModal(
    (close) => (
      <SubscribtionDialog
        onSubscribe={async (params: SubscribtionParams) => {
          onSubscribe(params);

          close();
        }}
      />
    ),
    { maxWidth: "xs" },
    false,
  );

  return (
    <button
      className={classNames(className, styles.subscribtion, value && styles.subscribed)}
      onClick={() => {
        if (value) {
          onUnsubscribe();
        } else {
          openSubscribtionDialog();
        }
      }}
    >
      {value ? <Subscribed /> : <Subscribe />}
    </button>
  );
};

export default Subscribtion;
