import type { FC } from "react";

import Icon, { IconProps } from "../Icon";

import styles from "../icons.module.scss";

const SignOut: FC<IconProps> = (props) => {
  return (
    <Icon sizeCoef="2x" viewBoxCoef="2x" {...props}>
      <g className={styles.shape}>
        <path d="M4.61357 14.4175C4.44261 14.174 4.45503 13.8155 4.75238 13.8333C4.75238 13.8333 5.75223 13.9896 6.99964 13.9896C8.26082 13.9896 9.24678 13.8336 9.24678 13.8336C9.5433 13.8125 9.55938 14.1467 9.39222 14.3921C8.94062 15.0552 7.95264 15.5 6.99964 15.5C6.05899 15.5 5.06903 15.0664 4.61357 14.4175Z" />
        <path d="M12.833 2.16667C13.2932 2.16667 13.6663 2.53976 13.6663 3V3.83333H14.4997C14.9599 3.83333 15.333 4.20643 15.333 4.66667C15.333 5.1269 14.9599 5.5 14.4997 5.5H13.6663V6.33333C13.6663 6.79357 13.2932 7.16667 12.833 7.16667C12.3728 7.16667 11.9997 6.79357 11.9997 6.33333V5.5H11.1663C10.7061 5.5 10.333 5.1269 10.333 4.66667C10.333 4.20643 10.7061 3.83333 11.1663 3.83333H11.9997V3C11.9997 2.53976 12.3728 2.16667 12.833 2.16667Z" />
        <path d="M6.99967 0.5C7.45991 0.5 7.83301 0.873096 7.83301 1.33333C8.13441 1.36564 8.41142 1.4162 8.66634 1.48277C9.49967 1.7004 9.49967 2.1177 9.24678 2.54406C8.87804 3.16571 8.66634 3.89147 8.66634 4.66667C8.66634 6.96785 10.5318 8.83333 12.833 8.83333C13.2497 8.83333 13.6663 8.83333 13.6663 9.66669C13.6663 11.3334 11.1738 13 6.99967 13C2.82557 13 0.332995 11.3334 0.333008 9.66669C0.333008 8.78766 0.699094 8.32073 1.08289 7.83121C1.24487 7.62462 1.41 7.414 1.5521 7.16668C1.78557 6.76035 1.93077 6.20542 2.08959 5.59851L2.09463 5.57925C2.54788 3.84696 3.12026 1.65937 6.16634 1.33333C6.16634 0.873096 6.53944 0.5 6.99967 0.5Z" />
      </g>
    </Icon>
  );
};

export default SignOut;
