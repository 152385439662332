import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import BasicDialog from "./Basic";
import { fetchTasks } from "../services/api";

const FieldsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    cursor: pointer;
  }
`;

const HistoryOvelay = (props) => {
  const [historyList, setHistoryList] = useState([]);

  useEffect(() => {
    const fetchController = new AbortController();

    (async () => {
        const { data: searchHistory } = await fetchTasks({
          signal: fetchController.signal,
        });

        setHistoryList(searchHistory);
    })();

    return () => {
        fetchController.abort();
    };
  }, []);

  return (
    <BasicDialog {...props}>
      <FieldsList>
        {historyList.map((i) => (
          <NavLink
            to={`/search/task/${i.id}`}
            key={i.id}
            style={{
              opacity: i.status === "Finished" ? 1 : 0.1,
              pointerEvents: i.status === "Error" ? "none" : "auto",
            }}
          >
            <Typography
              sx={{ mt: 1 }}
              variant="h4"
              style={{ display: "flex", alignItems: "center" }}
            >
              {i.search_query}
              {i.status === "Error" && <Cancel style={{ marginLeft: "8px" }} />}
            </Typography>
          </NavLink>
        ))}
      </FieldsList>
    </BasicDialog>
  );
};

export default HistoryOvelay;
