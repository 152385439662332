import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TERMINAL_STATES } from "./const";

const selectSearch = (state: RootState) => state.Search;

export const selectTask = createSelector(selectSearch, (search) => search.task);

export const selectState = createSelector(
  selectSearch,
  (search) => search.state,
);

export const selectResult = createSelector(selectSearch, (search) => {
  return search.result && search.result.length
    ? [
        search.result[0],
        ...search.result.slice(1).sort((a, b) => b.growth! - a.growth!),
      ]
    : [];
});

export const selectFocusedCluster = createSelector(selectSearch, (search) => {
  return (
    (search.focusedIndex !== null &&
      search.result.find((c) => c.id == search.focusedIndex)) ||
    null
  );
});

export const isLoading = createSelector(
  selectState,
  (state) => !!state && !TERMINAL_STATES.includes(state),
);
