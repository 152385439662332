import { type FC, useState } from "react";
import { Dropdown } from "@mui/base";
import { ClickAwayListener, Popper } from "@mui/material";
import Angle from "~/icons/Angle";
import styles from "./styles.module.scss";
import { Sorting } from "~/state/filters";

interface SortingMenuProps {
  value: Sorting;
  options: {
    date_asc: string;
    date_desc: string;
    cited_asc: string;
    cited_desc: string;
    relevance?: string;
  };
  onChange: (value: Sorting) => void;
}

const SortingMenu: FC<SortingMenuProps> = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown>
      <button
        className={styles.button}
        id="eai-sorting-menu-button"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Angle
          width={16}
          height={16}
          className={styles.shevron}
          style={{ transform: isOpen ? "rotateX(180deg)" : "" }}
        />
        {options[value]}
      </button>
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Popper
            open={true}
            anchorEl={document.getElementById("eai-sorting-menu-button")}
            placement="bottom-start"
            slots={{ root: "section" }}
            slotProps={{
              root: { className: styles.menu },
            }}
          >
            <ul>
              {Object.entries(options).map(([value, title]) => (
                <li
                  key={value}
                  onClick={() => {
                    onChange(value as Sorting);
                    setIsOpen(false);
                  }}
                >
                  {title}
                </li>
              ))}
            </ul>
          </Popper>
        </ClickAwayListener>
      )}
    </Dropdown>
  );
};

export default SortingMenu;
